import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';
import { getUserType } from '../../util/helper';
import { useSelector } from 'react-redux';
import { ATHLETE, BRAND, SPORTTEAM } from '../../util/types';

const UserNav = props => {
  const { className, rootClassName, currentPage } = props;
  const { currentUser } = useSelector(state => state.user);
  const classes = classNames(rootClassName || css.root, className);
  const userType = getUserType(currentUser);


  const tabs = [ 
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];


  if ([BRAND].includes(userType)) {
    tabs.push({
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
      {
        text: <FormattedMessage id="UserNav.favoriteListings" />,
        selected: currentPage === 'FavoriteListingsPage',
        disabled: false,
        linkProps: {
          name: 'FavoriteListingsPage',
        },
      },
    );
  }
  if ([SPORTTEAM].includes(userType)) {
    tabs.push({
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    });
  }
  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
