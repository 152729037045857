/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FieldCheckbox, ValidationError } from '../../components';

import css from './FieldCheckboxGroup.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { maxLength } from '../../util/validators';

const FieldCheckboxRenderer = props => {
  const {
    className,
    rootClassName,
    label,
    optionLabelClassName,
    twoColumns,
    id, 
    fields,
    options,
    meta,
    showWith=false,
    maxLength="",
    checkedValues
  } = props;  
  const intl=useIntl(); 
  const hasReachedMaxLength = checkedValues?.length >= 8; 
  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(css.list, css.twoColumns) : css.list;  
  return (
    <fieldset className={classes}>
      {label ?
        <legend>
          {showWith ? label : <FormattedMessage id={`enum.label.${id}`} />}
        </legend>
        : null}
      <ul className={listClasses}>
        {options
          .sort((a, b) => {
            const labelA = showWith ? a.label : intl.formatMessage({ id: `enum.${a.key}` });
            const labelB = showWith ? b.label : intl.formatMessage({ id: `enum.${b.key}` });
            return labelA.localeCompare(labelB);
          })
          .map((option, index) => {
            const fieldId = `${id}.${option.key}`;
            const textClassName = optionLabelClassName;
            const textClassNameMaybe = textClassName ? { textClassName } : {};
            return (
              <li key={fieldId} className={css.item}>
                <FieldCheckbox
                  id={fieldId}
                  name={fields.name}
                  disabled={hasReachedMaxLength && !checkedValues.includes(option.key)}
                  label={showWith ? option.label : <FormattedMessage id={`enum.${option.key}`} />}
                  value={option.key}
                  maxLength={maxLength}
                  {...textClassNameMaybe}
                />
              </li>
            );
          })
        }

      </ul>
      <ValidationError fieldMeta={{ ...meta }} />
    </fieldset>
  );
};

FieldCheckboxRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  twoColumns: false,
};

FieldCheckboxRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  twoColumns: bool,
};

const FieldCheckboxGroup = props => <FieldArray component={FieldCheckboxRenderer} {...props} />;

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldCheckboxGroup.propTypes = {
  name: string.isRequired,
};

export default FieldCheckboxGroup;
