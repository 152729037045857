/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React, { useEffect, useState } from 'react';
import { node, string } from 'prop-types';

import { FormattedMessage } from '../../../util/reactIntl';

import { TabNav } from '../../../components';

import { createGlobalState } from './hookGlobalState';

import css from './LayoutSideNavigation.module.css';
import { getUserType } from '../../../util/helper';
import { ATHLETE, BRAND, SPORTTEAM } from '../../../util/types';
import { useSelector } from 'react-redux';

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

// Add global state for tab scrolling effect
const initialScrollState = { scrollLeft: 0 };
const { useGlobalState } = createGlobalState(initialScrollState);

// Horizontal scroll animation using element.scrollTo()
const scrollToTab = (currentPage, scrollLeft, setScrollLeft) => {
  const el = document.querySelector(`#${currentPage}Tab`);

  if (el) {
    // el.scrollIntoView doesn't work with Safari and it considers vertical positioning too.
    // This scroll behaviour affects horizontal scrolling only
    // and it expects that the immediate parent element is scrollable.
    const parent = el.parentElement;
    const parentRect = parent.getBoundingClientRect();
    const maxScrollDistance = parent.scrollWidth - parentRect.width;

    const hasParentScrolled = parent.scrollLeft > 0;
    const scrollPositionCurrent = hasParentScrolled ? parent.scrollLeft : scrollLeft;

    const tabRect = el.getBoundingClientRect();
    const diffLeftBetweenTabAndParent = tabRect.left - parentRect.left;
    const tabScrollPosition = parent.scrollLeft + diffLeftBetweenTabAndParent;

    const scrollPositionNew =
      tabScrollPosition > maxScrollDistance
        ? maxScrollDistance
        : parent.scrollLeft + diffLeftBetweenTabAndParent;

    const needsSmoothScroll = scrollPositionCurrent !== scrollPositionNew;

    if (parent.scrollTo && (!hasParentScrolled || (hasParentScrolled && needsSmoothScroll))) {
      // Ensure that smooth scroll animation uses old position as starting point after navigation.
      parent.scrollTo({ left: scrollPositionCurrent });
      // Scroll to new position
      parent.scrollTo({ left: scrollPositionNew, behavior: 'smooth' });
    }
    // Always keep track of new position (even if smooth scrolling is not applied)
    setScrollLeft(scrollPositionNew);
  }
};

const LayoutWrapperAccountSettingsSideNavComponent = props => {
  const { currentPage } = props;
  const [mounted, setMounted] = useState(false);
  const [scrollLeft, setScrollLeft] = useGlobalState('scrollLeft');

  useEffect(() => {
    setMounted(true);
  }, []);

  
  const { currentUser } = useSelector(state => state.user);
  const userType = getUserType(currentUser);
  
  useEffect(() => {
    if (mounted) {
      const { currentPage } = props;
      const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
      const hasHorizontalTabLayout = hasMatchMedia
        ? window.matchMedia(`(max-width: ${MAX_HORIZONTAL_NAV_SCREEN_WIDTH}px)`)?.matches
        : true;

      // Check if scrollToTab call is needed (tab is not visible on mobile)
      if (hasHorizontalTabLayout) {
        scrollToTab(currentPage, scrollLeft, setScrollLeft);
      }
    }
  }, [mounted]);


  
  const payoutTab = {
    text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />,
    selected: currentPage === 'StripePayoutPage',
    id: 'StripePayoutPageTab',
    linkProps: {
      name: 'StripePayoutPage',
    },
  }

  const clubPaymentTab = {
    text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.ClubPaymentTabTitle" />,
    selected: currentPage === 'ClubPaymentPage',
    id: 'ClubPaymentPageTab',
    linkProps: {
      name: 'ClubPaymentPage',
    },
  }
  const paymentMethodTab = {
    text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />,
    selected: currentPage === 'PaymentMethodsPage',
    id: 'PaymentMethodsPageTab',
    linkProps: {
      name: 'PaymentMethodsPage',
    },
  }
  const CardTab = {
    text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.CardTabTitle" />,
    selected: currentPage === 'CardPage',
    id: 'CardPageTab',
    linkProps: {
      name: 'CardPage',
    },
  }

  const tabs = [
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle" />,
      selected: currentPage === 'ContactDetailsPage',
      id: 'ContactDetailsPageTab',
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />,
      selected: currentPage === 'PasswordChangePage',
      id: 'PasswordChangePageTab',
      linkProps: {
        name: 'PasswordChangePage',
      },
    },

  ];
  // if (userType === SPORTTEAM || userType === ATHLETE) {
  //   tabs.push(payoutTab)
  // }

  // if ([ATHLETE,SPORTTEAM].includes(userType)) {
  //   tabs.push(clubPaymentTab)
  // }

  // if (userType === BRAND) {
  //   tabs.push(paymentMethodTab);
  // }

  if (userType === ATHLETE) {
    tabs.push(CardTab);
  }
 
  return <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />;
};

LayoutWrapperAccountSettingsSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentPage: null,
};

LayoutWrapperAccountSettingsSideNavComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentPage: string,
};

const LayoutWrapperAccountSettingsSideNav = LayoutWrapperAccountSettingsSideNavComponent;

export default LayoutWrapperAccountSettingsSideNav;
