import moment from 'moment';
import { storableError } from '../../util/errors';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

// ================ Action types ================ //

export const GENERATE_CARD_REQUEST = 'app/PasswordResetPage/GENERATE_CARD_REQUEST';
export const GENERATE_CARD_SUCCESS = 'app/PasswordResetPage/GENERATE_CARD_SUCCESS';
export const GENERATE_CARD_ERROR = 'app/PasswordResetPage/GENERATE_CARD_ERROR';

// ================ Reducer ================ //

const initialState = {
  createCardInProgress: false,
  createCardInError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GENERATE_CARD_REQUEST:
      return { ...state, createCardInProgress: true,   createCardInError: null };
    case GENERATE_CARD_SUCCESS:
      return { ...state, createCardInProgress: false };
    case GENERATE_CARD_ERROR:
      console.error(payload);  
      return { ...state, createCardInProgress: false,   createCardInError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const genertateCardRequest = () => ({ type: GENERATE_CARD_REQUEST });

export const genertateCardSuccess = () => ({ type: GENERATE_CARD_SUCCESS });

export const genertateCardError = e => ({
  type: GENERATE_CARD_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const genertateCard = () => async(dispatch, getState, sdk) => {
  dispatch(genertateCardRequest());

  try {
    const currentUser = getState().user.currentUser;
    const listingData= await sdk.ownListings.query({}); 
    const logoUrl = "https://images.unsplash.com/photo-1737879753234-ab5d7da35a1f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
    const cardText = "Sbonnsy Membership Card";
    const currentUserName = currentUser?.attributes?.profile?.displayName || "";
    const URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL + "/l/" + listingData.data.data[0].attributes.publicData.shareProfile
    const data = {
      cardNumber: "SB" + Math.floor(10000000 + Math.random() * 90000000),
      issuerName: "Sbonssy",
      cardholderName: currentUserName,
      logo: logoUrl,
      cardHeading: cardText, 
      shareProfile: URL
    };

    await dispatch(updateProfile({
      privateData: { membershipCard: data }
    }));
    
    dispatch(genertateCardSuccess());
  } catch (e) {
    dispatch(genertateCardError(storableError(e)))
  }
} 
