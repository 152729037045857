import React from 'react';


const IconCollection = ({ name }) => {
    switch (name) {


        case 'three_dots':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 40 40"><g fill="#000"><path d="M23.112 9.315a3.113 3.113 0 1 1-6.226.002a3.113 3.113 0 0 1 6.226-.002"/><circle cx="20" cy="19.999" r="3.112"/><circle cx="20" cy="30.685" r="3.112"/></g></svg>
            );
        case 'facebook_icon':
            return (
                <svg fill="#0000ff" height="25px" width="25px" version="1.1" id="Icons"
                    viewBox="0 0 32 32" >
                    <path d="M23,0H9C4,0,0,4,0,9v14c0,5,4,9,9,9h7V20h-4c-0.6,0-1-0.4-1-1v-3c0-0.6,0.4-1,1-1h4v-3c0-3.3,1.7-6,5-6h4c0.6,0,1,0.4,1,1v3
	c0,0.6-0.4,1-1,1h-3.8c-0.1,0-0.2,0.1-0.2,0.2V15h5c0.3,0,0.6,0.2,0.8,0.4s0.2,0.6,0.1,0.9l-2,3C24.8,19.8,24.4,20,24,20h-3v12h2
	c5,0,9-4,9-9V9C32,4,28,0,23,0z"/>
                </svg>
            );
        case 'tiktok_icon':
            return (
                <svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24"  ><path d="M21,2H3A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2Zm-3.281,8.725h0c-.109.011-.219.016-.328.017A3.571,3.571,0,0,1,14.4,9.129v5.493a4.061,4.061,0,1,1-4.06-4.06c.085,0,.167.008.251.013v2a2.067,2.067,0,1,0-.251,4.119A2.123,2.123,0,0,0,12.5,14.649l.02-9.331h1.914A3.564,3.564,0,0,0,17.719,8.5Z" /></svg>
            );
        case 'linkdin_icon':
            return (
                <svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" data-name="Layer 1"><path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z" /></svg>
            );
        case 'tiktok_icon':
            return (
                <svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24"><path d="M21,2H3A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2Zm-3.281,8.725h0c-.109.011-.219.016-.328.017A3.571,3.571,0,0,1,14.4,9.129v5.493a4.061,4.061,0,1,1-4.06-4.06c.085,0,.167.008.251.013v2a2.067,2.067,0,1,0-.251,4.119A2.123,2.123,0,0,0,12.5,14.649l.02-9.331h1.914A3.564,3.564,0,0,0,17.719,8.5Z" /></svg>
            );
        case 'twitter_icon':
            return (
                <svg fill="#000000" height="25px" width="25px" version="1.1" id="Layer_1"
                    viewBox="-143 145 512 512" >
                    <path d="M-143,145v512h512V145H-143z M215.2,361.2c0.1,2.2,0.1,4.5,0.1,6.8c0,69.5-52.9,149.7-149.7,149.7
               c-29.7,0-57.4-8.7-80.6-23.6c4.1,0.5,8.3,0.7,12.6,0.7c24.6,0,47.3-8.4,65.3-22.5c-23-0.4-42.5-15.6-49.1-36.5
               c3.2,0.6,6.5,0.9,9.9,0.9c4.8,0,9.5-0.6,13.9-1.9C13.5,430-4.6,408.7-4.6,383.2v-0.6c7.1,3.9,15.2,6.3,23.8,6.6
               c-14.1-9.4-23.4-25.6-23.4-43.8c0-9.6,2.6-18.7,7.1-26.5c26,31.9,64.7,52.8,108.4,55c-0.9-3.8-1.4-7.8-1.4-12
               c0-29,23.6-52.6,52.6-52.6c15.1,0,28.8,6.4,38.4,16.6c12-2.4,23.2-6.7,33.4-12.8c-3.9,12.3-12.3,22.6-23.1,29.1
               c10.6-1.3,20.8-4.1,30.2-8.3C234.4,344.5,225.5,353.7,215.2,361.2z"/>
                </svg>
            );
        case 'instagram_icon':
            return (
                <svg height="25px" width="25px" viewBox="0 0 15 15" fill="none">
                    <path d="M7.5 5C6.11929 5 5 6.11929 5 7.5C5 8.88071 6.11929 10 7.5 10C8.88071 10 10 8.88071 10 7.5C10 6.11929 8.88071 5 7.5 5Z" fill="#000000" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 0C2.01472 0 0 2.01472 0 4.5V10.5C0 12.9853 2.01472 15 4.5 15H10.5C12.9853 15 15 12.9853 15 10.5V4.5C15 2.01472 12.9853 0 10.5 0H4.5ZM4 7.5C4 5.567 5.567 4 7.5 4C9.433 4 11 5.567 11 7.5C11 9.433 9.433 11 7.5 11C5.567 11 4 9.433 4 7.5ZM11 4H12V3H11V4Z" fill="#000000" />
                </svg>

            );

        case 'youtube_icon':
            return (
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 17.8571L16.4875 12.5L10 7.14286V17.8571ZM24.45 3.875C24.6125 4.71429 24.725 5.83928 24.8 7.26786C24.8875 8.69643 24.925 9.92857 24.925 11L25 12.5C25 16.4107 24.8 19.2857 24.45 21.125C24.1375 22.7321 23.4125 23.7679 22.2875 24.2143C21.7 24.4464 20.625 24.6071 18.975 24.7143C17.35 24.8393 15.8625 24.8929 14.4875 24.8929L12.5 25C7.2625 25 4 24.7143 2.7125 24.2143C1.5875 23.7679 0.8625 22.7321 0.55 21.125C0.3875 20.2857 0.275 19.1607 0.2 17.7321C0.1125 16.3036 0.0749999 15.0714 0.0749999 14L0 12.5C0 8.58929 0.2 5.71429 0.55 3.875C0.8625 2.26786 1.5875 1.23214 2.7125 0.785714C3.3 0.553572 4.375 0.392857 6.025 0.285714C7.65 0.160714 9.1375 0.107143 10.5125 0.107143L12.5 0C17.7375 0 21 0.285714 22.2875 0.785714C23.4125 1.23214 24.1375 2.26786 24.45 3.875Z" fill="black" />
                </svg>
            );
        case 'linkedin_icon':
            return (
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.2222 0C22.9589 0 23.6655 0.292658 24.1864 0.813592C24.7073 1.33453 25 2.04107 25 2.77778V22.2222C25 22.9589 24.7073 23.6655 24.1864 24.1864C23.6655 24.7073 22.9589 25 22.2222 25H2.77778C2.04107 25 1.33453 24.7073 0.813592 24.1864C0.292658 23.6655 0 22.9589 0 22.2222V2.77778C0 2.04107 0.292658 1.33453 0.813592 0.813592C1.33453 0.292658 2.04107 0 2.77778 0H22.2222ZM21.5278 21.5278V14.1667C21.5278 12.9658 21.0507 11.8142 20.2016 10.965C19.3525 10.1159 18.2008 9.63889 17 9.63889C15.8194 9.63889 14.4444 10.3611 13.7778 11.4444V9.90278H9.90278V21.5278H13.7778V14.6806C13.7778 13.6111 14.6389 12.7361 15.7083 12.7361C16.224 12.7361 16.7186 12.941 17.0833 13.3056C17.4479 13.6703 17.6528 14.1649 17.6528 14.6806V21.5278H21.5278ZM5.38889 7.72222C6.00773 7.72222 6.60122 7.47639 7.03881 7.03881C7.47639 6.60122 7.72222 6.00773 7.72222 5.38889C7.72222 4.09722 6.68056 3.04167 5.38889 3.04167C4.76637 3.04167 4.16934 3.28896 3.72915 3.72915C3.28896 4.16934 3.04167 4.76637 3.04167 5.38889C3.04167 6.68056 4.09722 7.72222 5.38889 7.72222ZM7.31944 21.5278V9.90278H3.47222V21.5278H7.31944Z" fill="black" />
                </svg>

            );
        case 'backgroundCircle':
            return (
                <svg width="437" height="622" viewBox="0 0 437 622" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="0.5" cy="309.5" r="436.5" fill="#F97C30" />
                </svg>
            );
        case 'love_icon':
            return (
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="15" cy="15" r="15" fill="white" />
                    <path d="M15.004 23C14.7377 23 14.4993 22.898 14.3164 22.7097L9.05862 17.575C9.02285 17.5514 8.93145 17.4651 8.8162 17.3435C8.71287 17.2337 8.54595 17.0375 8.31943 16.7512C8.0929 16.4688 7.89021 16.1706 7.71138 15.8686C7.52459 15.5587 7.36165 15.1821 7.21858 14.7506C7.07154 14.3034 7 13.8602 7 13.4365C7 12.0675 7.40537 10.977 8.2002 10.1886C8.99901 9.40011 10.0959 9 11.4749 9C11.8684 9 12.2737 9.07061 12.687 9.2079C13.0845 9.34127 13.462 9.52564 13.8038 9.75315C14.1297 9.9689 14.4118 10.1729 14.6423 10.3651C14.7655 10.4592 14.8848 10.5651 15 10.6711C15.1153 10.5651 15.2384 10.4592 15.3577 10.3651C15.5921 10.1729 15.8703 9.9689 16.1962 9.75315C16.538 9.52564 16.9156 9.34127 17.313 9.2079C17.7223 9.07061 18.1277 9 18.5251 9C19.9001 9 21.001 9.40011 21.7998 10.1886C22.5946 10.977 23 12.0675 23 13.4365C23 14.8055 22.3085 16.202 20.9414 17.5828L15.6836 22.7136C15.5007 22.8941 15.2663 22.9961 15 22.9961L15.004 23Z" fill="#F26915" />
                </svg>
            );
        default:
            return <></>;
    }
};

export default IconCollection;